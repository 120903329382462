const trans = {
  fr: {
    'menu-1': 'Accueil',
    'menu-2': 'À propos',
    'menu-3': 'Stratégies',
    'menu-4': 'Contact',
    Team: 'Équipe',

    'footer-1': 'ESG Policy',
    'footer-2': 'Déclaration en matière de protection des données',
    'footer-3': 'Avis juridique ',

    'hero-title-1': 'Gestionnaire d’actifs spécialisé en actions mondiales',
    'hero-title-2': 'Gestion d’actifs PineStone inc.',

    'privacy-title': 'Déclaration en matière<br>de protection des données',

    more: 'En savoir plus',

    'about-title-1': 'À propos de PineStone',
    'about-title-2': `Basée à Montréal<br>Fondée en 2021 par <a href="/fr/nadim-rizk">Nadim Rizk</a>`,
    'about-desc':
      'PineStone est une entreprise détenue entièrement par ses employés et dirigée par son fondateur. Nous servons notre clientèle en investissant dans des entreprises de qualité inégalable au niveau mondial et en conservant leurs titres le plus longtemps possible, souvent durant des décennies. Nous sommes d’avis que la croissance composée de ces entreprises peut créer une valeur exceptionnelle à long terme pour nos clients.',

    'home-stat-1': `<p class="big">+25</p>
                    <p class="regular-text">années<br>d’expérience</p>`,
    'home-stat-2': `<p class="big">EMPHASE</p>
                    <p class="regular-text">particulière sur les entreprises de qualité</p>`,
    'home-stat-3': `<p class="big">Harmonisation</p>
                    <p class="regular-text">des intérêts au travers de l'actionnariat, de la rémunération et des investissements.</p>`,

    'about-hero-1': 'À propos de nous',
    'about-hero-2': 'Gestion d’actifs PineStone inc.',

    'about-content-title-1': 'Fondée en 2021',
    'about-content-desc-1': `<h4 class="title small">Gestion d’actifs PineStone inc.</h4>
                             <p class="regular-text"> a été créée lors de la séparation de notre équipe de placement d’un important gestionnaire d’actifs coté en bourse. Travaillant ensemble depuis plus de 10 ans, la contribution de notre équipe au sein de l’industrie a été récompensée par de nombreuses distinctions d’excellences. PineStone est fière de compter parmi ses clients certains des investisseurs institutionnels les plus sophistiqués au monde.</p>`,

    'about-content-title-2': 'Patience active',
    'about-content-desc-2': `<h4 class="title small">Nous faisons preuve d’une « patience active » </h4>
                             <p class="regular-text">avec nos placements, en regardant au-delà des défis à court terme et en effectuant le travail de recherche nécessaire afin de nous assurer que nos thèses d’investissement demeurent valides. Nous sommes d'avis que notre esprit de véritables propriétaires d'entreprises, ainsi que la connaissance approfondie que nous acquérons en détenant nos titres au travers de nombreux cycles de marché, comptent parmi nos atouts majeurs.</p>`,

    'about-content-title-3': 'Que signifie notre nom&nbsp;?',
    'about-content-desc-3': `<p class="regular-text"><strong style="font-size: 150%;">Le logo de PineStone présente un pin en forme de parapluie originaire de la Méditerranée, incluant le Liban.</strong> <br/><br/> Nous imaginons que la forme en parapluie de l'arbre représente la protection des actifs de nos clients, qu’assure notre équipe d’investissement contre les variations des marchés financiers. Les pignons, quant à eux, représentent les dividendes que nos entreprises paient à nos clients au fil des nombreuses années que nous détenons une participation dans celles-ci.</p>`,

    'about-content-title-4': 'Pin parasol (Pinus Pinea)',

    'about-stat-1': `<p class="big mh"><span class="top">Notre</span><br>mission</p>
                     <p class="regular-text small">Créer une valeur exceptionnelle pour nos clients et leurs bénéficiaires pour de nombreuses années.</p>`,
    'about-stat-2': `<p class="big mh"><span class="top">Notre</span><br>vision</p>
                     <p class="regular-text small">Devenir la référence incontournable dans le domaine de la recherche fondamentale, de la construction de portefeuille et des placements de croissance de qualité à long terme. </p>`,
    'about-stat-3': `<p class="big mh"><span class="top">Nos</span><br>valeurs</p>
                     <p class="regular-text small">Nous favorisons une culture axée sur l’engagement, l’intégrité, le dynamisme, la rigueur et la patience.</p>`,

    'strat-hero-1': 'Nos',
    'strat-hero-2': 'Stratégies',

    'strat-content-title-1': 'CONVICTIONS SOLIDES',
    'strat-content-desc-1': `<h4 class="title small">PineStone gère des stratégies d’actions à forte conviction </h4>
                             <p class="regular-text">et à long terme pour une clientèle principalement institutionnelle. Nous investissons dans un nombre moins élevé de titres, tout en maintenant une diversification adéquate, car nous visons à générer des rendements excédentaires importants pour nos clients. Notre objectif est de dépasser le marché à long terme, afin de minimiser le risque de perte permanente de capital. Nous sommes investis dans nos stratégies aux cotés de nos clients.</p>`,

    'strat-content-title-2': 'GUIDÉS PAR LA RECHERCHE',
    'strat-content-desc-2': `<h4 class="title small">Pour nous aider à atteindre nos objectifs de rendement,</h4>
                             <p class="regular-text">nous avons mis en place un processus intensif de recherche fondamentale purement « bottom-up » afin d’évaluer si nos investissements potentiels possèdent les attributs particuliers de qualité que nous recherchons et si ceux-ci peuvent être maintenus à long terme. Les entreprises qui répondent à nos critères sont difficiles à trouver, ce qui conduit à des portefeuilles concentrés et des écarts significatifs par rapport à nos indices de référence.</p>`,

    'strat-stat-1': `<p class="big mh invert"><span class="bottom">Actions</span> mondiales</p>
                    <div class="table">
                      <div class="row">
                        <div class="cell color"><p>Date de création</p></div>
                        <div class="cell"><p>1er octobre 2009</p></div>
                      </div>
                      <div class="row">
                        <div class="cell color"><p>Nombre de titres cible</p></div>
                        <div class="cell"><p>25-35</p></div>
                      </div>
                      <div class="row">
                        <div class="cell color"><p>Taux de rotation annuel</p></div>
                        <div class="cell"><p>5-10%</p></div>
                      </div>
                    </div>
    `,
    'strat-stat-2': `<p class="big mh invert"><span class="bottom">Actions</span> internationales</p>
                    <div class="table">
                      <div class="row">
                        <div class="cell color"><p>Date de création</p></div>
                        <div class="cell"><p>1er janvier 2010</p></div>
                      </div>
                      <div class="row">
                        <div class="cell color"><p>Nombre de titres cible</p></div>
                        <div class="cell"><p>25-35</p></div>
                      </div>
                      <div class="row">
                        <div class="cell color"><p>Taux de rotation annuel</p></div>
                        <div class="cell"><p>5-10%</p></div>
                      </div>
                    </div>                 
    `,
    'strat-stat-3': `<p class="big mh invert"><span class="bottom">Actions</span> américaines</p>
                    <div class="table">
                      <div class="row">
                        <div class="cell color"><p>Date de création</p></div>
                        <div class="cell"><p>1er avril 2009</p></div>
                      </div>
                      <div class="row">
                        <div class="cell color"><p>Nombre de titres cible</p></div>
                        <div class="cell"><p>20-30</p></div>
                      </div>
                      <div class="row">
                        <div class="cell color"><p>Taux de rotation annuel</p></div>
                        <div class="cell"><p>5-10%</p></div>
                      </div>
                    </div>
    `,

    'nadim-hero-1': 'À propos du fondateur',
    'nadim-hero-2': 'Nadim Rizk, MBA, CFA',
    'nadim-title': 'Chef de la direction et chef des placements',
    'nadim-desc': `Nadim Rizk a fondé Gestion d’actifs PineStone en 2021, réalisant ainsi son plus grand rêve professionnel, soit d’établir une société d’investissement détenue par ses employés, dédiée à son approche de placement et dévouée à ses clients. 
    <br/><br/>
    Nadim est né et a grandi à Beyrouth, au Liban. Dès l’adolescence, il a été initié au monde de l’investissement par son père, un médecin de famille dévoué. En plus d’éveiller en lui une passion pour les placements, ses parents lui ont inculqué les principes de l’engagement, de l’intégrité, de l’éthique professionnelle et de l’esprit de famille. Ces valeurs sont aujourd’hui ancrées dans la culture de PineStone et contribuent à guider notre approche rigoureuse ainsi que notre engagement envers nos clients. 
    <br/><br/>
    Nadim a obtenu un baccalauréat en commerce avec concentration en finance de l’Université américaine de Beyrouth. Il a déménagé au Canada pour poursuivre sa maîtrise en administration des affaires à l’Université McGill et a obtenu le titre d’analyste financier agréé (CFA). Il siège au conseil d’administration de la Fondation du cancer des Cèdres et du Centre Universitaire de Santé de McGill, et participe activement en tant qu’expert mondial au programme « Honours in Investment Management » de l’Université McGill. Il vit à Montréal avec sa femme et leurs trois enfants.
`,

    'contact-hero-1': 'Nous contacter',

    'meta-title-home': 'Gestionnaire d’actions mondiales spécialisé',
    'meta-title-about': 'Gestion d’actifs PineStone inc.',
    'meta-title-nadim': 'Nadim Rizk',
    'meta-title-strategies': 'Stratégies PineStone',
    'meta-title-contact': 'Contact PineStone',
    'meta-desc':
      'PineStone est une entreprise détenue entièrement par ses employés et dirigée par son fondateur. Nous servons notre clientèle en investissant dans des entreprises que nous considérons comme étant de la plus haute qualité à l’échelle mondiale.',

    'contact-address': '1981, avenue McGill College, bureau 1600<br>Montréal (Québec)  H3A 2Y1',
    'contact-phone': 'T : 438-793-0444',
    'contact-email': 'C : info@pinestoneam.com',
    'contact-cta-email': 'Écrivez-nous',
    'form-title': 'Envoyez-nous un courriel',
    'form-name': 'Prénom et nom de famille',
    'form-mail': 'Courriel',
    'form-phone': 'Numéro de téléphone',
    'form-business': 'Nom de l’organisation',
    'form-investisseur': 'Type d’investisseur',
    'form-investisseur-option-1': 'Investisseur institutionnel',
    'form-investisseur-option-2': 'Consultant en investissement',
    'form-investisseur-option-3': 'Autre',
    'form-interet': 'Intérêt',
    'form-interet-option-1': 'Actions mondiales',
    'form-interet-option-2': 'Actions internationales',
    'form-interet-option-3': 'Actions américaines',
    'form-send': 'Envoyer',

    Sustainability: 'Durabilité',
    'As long-term investors': 'En tant qu’investisseur à long terme',
    'PineStone conducts a thorough analysis of the sustainability opportunities and challenges facing each portfolio company. We utilize our long tenure as shareholders to engage with our portfolio companies and advocate for what we believe to be in the best interests of minority shareholders. As prudent stewards of our client’s capital, we believe it is our obligation to undertake this analysis. As investors alongside our clients, and engaged citizens, we believe it is best for long-term returns and society.':
      'PineStone effectue une analyse approfondie des opportunités et des défis en matière de durabilité auxquels est confrontée chaque société du portefeuille. Nous utilisons notre longue expérience en tant qu’actionnaires pour nous engager auprès des sociétés de notre portefeuille et défendre ce que nous croyons être les meilleurs intérêts des actionnaires minoritaires. En tant que gestionnaire prudent du capital de nos clients, nous estimons qu’il est de notre devoir d’entreprendre cette analyse. En tant qu’investisseurs aux côtés de nos clients, et en tant que citoyens engagés, nous croyions qu’il est dans le meilleur intérêt des rendements à long terme et de la société.',
    Policies: 'Politiques',
    Collaborations: 'Collaborations',
    'We build relationships': 'Collaborations',
    'By working with select organizations that help us stay current on different and evolving perspectives on sustainability issues. We apply what we learn to our investment research process and into productive engagement with companies that we invest in.':
      'En collaborant avec des organisations sélectionnées qui nous aident à nous maintenir au courant des perspectives différentes et évolutives sur les questions de durabilité. Nous appliquons nos connaissances à notre processus de recherche sur les investissements et à un engagement productif avec les entreprises dans lesquelles nous investissons.',
    'Responsible Investment Policy': 'Politique d’investissement responsable',
    'Sustainability Risk Policy': 'Politique de risque en matière de durabilité',
    'Principle Adverse Impacts Statement': 'Déclaration sur les principales incidences négatives',
    'Proxy Voting Guidelines': 'Lignes directrices – vote par procuration',
    Download: 'Télécharger',

    'Firmwide Pinestone Sustainability Policies': "Politiques de l'entreprise en matière de durabilité",
    'SFDR Article 8 Funds Sustainability Policies': "Politiques de durabilité des fonds en conformité avec l'article 8 du SFDR",

    'Remuneration Policy': 'Politique de rémunération',

    'team-hero-1': 'Notre',
    'team-hero-2': 'Équipe',
    'team-title-1': 'L’Équipe de placement de PineStone',
    'team-title-2': 'L’équipe de placement, petites capitalisations',
    'team-title-3': 'Le comité de gestion de PineStone',
    bio: 'Biographie',

    'chief-compliance': 'Chef de la conformité',
    'chief-compliance-text':
      'Gestion d’actfs PineStone inc. a désigné David Doumani, Chef de la conformité, à titre de Responsable de la protection des renseignements personnels. Pour toute question concernant cette Déclaration en matière de protection des données, votre Responsable de la protection des renseignements personnels ou, plus généralement le traitement de vos données personnelles, vous pouvez contacter le Responsable de la protection des renseignements personnels à l’adresse suivante :',
    'chief-compliance-sub-title': 'Responsable de la protection des renseignements personnels',
    'chief-compliance-sub-text':
      'David Doumani<br>Responsable de la protection des renseignements personnels<br><br>Gestion d’actifs PineStone Inc.<br>1600 McGill College Avenue, Suite 1600<br>Montreal (Quebec) H3A 2Y1',

    'policies-title': 'Politiques',

    Team1: [
      {
        img: '/img/team/nadim-rizk-2.jpg',
        imgsmall: '/img/team/nadim-rizk-small.png',
        split: 'w50',
        name: 'Nadim Rizk, MBA, CFA<br>Fondateur',
        poste: 'Chef de la direction et chef des placements',
        biography: `
            <p class="regular-text">Nadim Rizk a fondé gestion d’actifs PineStone en 2021 et agit à titre de PDG et de chef des placements de la société. En tant qu’architecte de la philosophie et du processus de placement de la société, il est le gestionnaire de portefeuille principal des stratégies d’actions américaines, internationales et mondiales depuis leur création. Auparavant, M. Rizk était chef de l’équipe Actions mondiales chez Fiera Capital. Avant de se joindre à Fiera Capital, il était associé et gestionnaire de portefeuille principal des stratégies d’actions mondiales et américaines dans une société de placement privée à Montréal. M. Rizk a commencé sa carrière comme analyste de recherche sur les actions pour CN Investments. M. Rizk siège au conseil d’administration et préside le comité d’investissement de la Fondation du cancer des Cèdres. Il est également expert mondial Desautels pour le programme Honors in Investment Management de l’Université McGill.<br><br>M. Rizk est titulaire d’un baccalauréat en commerce de l’Université américaine de Beyrouth et d’une maîtrise en administration des affaires de l’Université McGill. Il est titulaire d’une charte CFA du CFA Institute.</p>
        `
      },
      {
        img: '/img/team/andrew-chan.jpg',
        imgsmall: '/img/team/andrew-chan-small.png',
        split: 'w50',
        name: 'Andrew Chan, CIM, M.Sc.<br>Partenaire',
        poste: 'Directeur de recherche',
        biography: `
            <p class="regular-text">Andrew Chan est directeur de recherche chez PineStone. M. Chan est responsable de l’élaboration continue du processus de recherche fondamentale de la société et des outils exclusifs utilisés par l’équipe. M. Chan collabore avec le PDG et chef des placements dans la prise de décisions concernant l’élaboration du portefeuille, ainsi qu’avec les analystes de la société pour établir les priorités de recherche, créer et peaufiner les modèles financiers et assurer l’uniformité des documents de recherche définitifs. M. Chan est le gestionnaire de portefeuille suppléant des stratégies de la société et continue d’assumer la responsabilité de la recherche pour certains des postes de base du portefeuille. Avant de participer à la fondation de PineStone, M. Chan travaillait comme directeur de la recherche pour l’équipe Actions mondiales de Fiera Capital. Il a également occupé des postes de recherche sur les actions de deux sociétés de placement privées établies à Montréal.<br><br>M. Chan est titulaire d’un baccalauréat en commerce de l’Université McGill et d’une maîtrise en finance de HEC Montréal. Il détient le titre de gestionnaire de placements agréé de CSI.</p>
        `
      },
      {
        img: '/img/team/nick-cileli.jpg',
        imgsmall: '/img/team/nick-cileli-small.png',
        split: '',
        name: 'Nick Cileli, CFA<br>Partenaire',
        poste: 'Analyste principal',
        biography: `
            <p class="regular-text">Nick Cileli est analyste principal chez PineStone, et chargé de la recherche pour le secteur des services financiers mondiaux. Avant de se joindre à PineStone, M. Cileli était analyste principal au sein de l’équipe de Fiera Capital, où il était l’un des cinq premiers membres. Pendant plus de 25 ans, M. Cileli a occupé des postes dans les domaines de la recherche sur les actions, de la gestion de la trésorerie et de la vérification financière.<br><br>M. Cileli est titulaire d’un baccalauréat en commerce, finance de l’Université Concordia et il est titulaire d’une charte CFA du CFA Institute.</p>
        `
      },
      {
        img: '/img/team/dominic-tremblay.jpg',
        imgsmall: '/img/team/dominic-tremblay-small.png',
        split: '',
        name: 'Dominic Tremblay, M.Sc, CFA<br>Partenaire',
        poste: 'Analyste principal',
        biography: `
            <p class="regular-text">Dominic Tremblay est analyste principal chez PineStone, et ses activités de recherche couvrent le secteur des soins de santé mondiaux ainsi que la couverture secondaire des services financiers mondiaux. Avant de se joindre à PineStone, M. Tremblay était analyste principal au sein de l’équipe de Fiera Capital, où il était l’un des cinq premiers membres. Pendant plus de 20 ans, M. Tremblay a occupé des postes en recherche sur les actions et en analyse financière.<br><br>M. Tremblay est titulaire d’un baccalauréat en administration des affaires, finance et d’une maîtrise ès sciences en finance de l’Université de Sherbrooke. Il est titulaire d’une charte CFA du CFA Institute.</p>
        `
      },
      {
        img: '/img/team/juhua-shi.jpg',
        imgsmall: '/img/team/juhua-shi-small.png',
        split: '',
        name: 'Juhua Shi, MBA, CFA<br>Partenaire',
        poste: 'Analyste principal',
        biography: `
            <p class="regular-text">Juhua Shi est analyste principal chez PineStone et assure la recherche pour les secteurs des technologies de l’information et des télécommunications. Avant de se joindre à PineStone, M. Shi était analyste principal au sein de l’équipe de Fiera Capital pendant près d’une décennie. Débutant sa carrière en Chine en tant qu’ingénieur informatique, M. Shi compte plus de 30 ans d’expérience dans la recherche sur les actions, la gestion de portefeuille et l’ingénierie.<br><br>M. Shi est titulaire d’un baccalauréat en génie informatique de l’Université Jiao Tong de Shanghai, d’une maîtrise en administration des affaires de l’Université McGill et d’une charte CFA du CFA Institute.</p>
        `
      },
      {
        img: '/img/team/wen-xu.jpg',
        imgsmall: '/img/team/wen-xu-small.png',
        split: '',
        name: 'Wen Qing Xu, CFA<br>Partenaire',
        poste: 'Analyste principal',
        biography: `
            <p class="regular-text">Wen Qing Xu est analyste principale chez PineStone et assure la recherche pour les secteurs de la consommation à l’échelle mondiale. Avant de se joindre à PineStone, Mme Xu était analyste au sein de l’équipe de Fiera Capital pendant plus de cinq ans.<br><br>Mme Xu est titulaire d’un baccalauréat en commerce, finance de l’Université McGill et titulaire d’une charte de CFA du CFA Institute.</p>
        `
      },
      {
        img: '/img/team/ayssar-fernandez.jpg',
        imgsmall: '/img/team/ayssar-fernandez-small.png',
        split: '',
        name: 'Ayssar Fernandez, CFA<br>Partenaire',
        poste: 'Analyste principal',
        biography: `
            <p class="regular-text">Ayssar Fernandez est analyste principal chez PineStone et assure la recherche pour les secteurs des technologies de l’information et les secteurs de la consommation à l’échelle mondiale. Avant de se joindre à PineStone, M. Fernandez était analyste au sein de l’équipe de Fiera Capital pendant plus de cinq ans.<br><br>M. Fernandez est titulaire d’un baccalauréat en commerce, finance de l’Université Concordia et d’une charte CFA du CFA Institute.</p>
        `
      },
      {
        img: '/img/team/stephano-pascali.jpg',
        imgsmall: '/img/team/stephano-pascali-small.png',
        split: '',
        name: 'Stéphane Pascali',
        poste: 'Analyste junior',
        biography: `
            <p class="regular-text">Stéphane Pascali est analyste junior chez PineStone et occupe le poste de généraliste.<br><br>M. Pascali est titulaire d’un baccalauréat en commerce, finance de l’Université Concordia.</p>
        `
      }
    ],

    Team2: [
      {
        img: '/img/team/thomas-horvath.jpg',
        imgsmall: '/img/team/thomas-horvath-small.png',
        split: '',
        name: 'Thomas Horvath, MBA, CFA, CAIA<br>Partenaire',
        poste: 'Gestionnaire de portefeuille principal',
        biography: `
            <p class="regular-text">Thomas Horvath est le gestionnaire de portefeuille principal de la stratégie mondiale des petites capitalisations. Avant de se joindre à PineStone, M. Horvath était analyste en chef pour l’équipe d’actions mondiales de Fiera Capital, où il était l’un des cinq premiers membres en tant que un des membres.<br><br>M. Horvath est titulaire d’un baccalauréat en commerce, finance de l’Université Concordia, d’une maîtrise en administration des affaires de l’Université Queens et d’une charte CFA de du CFA Institute. M. Horvath détient également le titre de CAIA.</p>
        `
      },
      {
        img: '/img/team/ivana-miladinovic.jpg',
        imgsmall: '/img/team/ivana-miladinovic-small.png',
        split: '',
        name: 'Ivana Miladinovic, CFA',
        poste: 'Analyste en chef',
        biography: `
            <p class="regular-text">Ivana Miladinovic est analyste en chef de la stratégie mondiale de PineStone pour les petites capitalisations et responsable de la recherche principale pour les secteurs mondiaux des entreprises industrielles et des services financiers. En plus de son travail sur la stratégie mondiale des petites capitalisations, Mme Miladinovic continue de soutenir provisoirement les stratégies pour les grandes capitalisations. Avant de se joindre à PineStone, Mme Miladinovic a travaillé pendant plus de six ans au sein de l’équipe de Fiera Capital. Elle compte plus de treize ans d’expérience en recherche sur les actions et en services bancaires d’investissement.<br><br>Mme Miladinovic a obtenu un baccalauréat en commerce, finance de l’Université Concordia et est titulaire d’une charte CFA du CFA Institute. Mme Miladinovic a également obtenu un certificat de placement ESG de niveau 4 de CFA UK.</p>
        `
      },
      {
        img: '/img/team/jackson-roy.jpg',
        imgsmall: '/img/team/jackson-roy-small.png',
        split: '',
        name: 'Jackson Roy',
        poste: 'Analyste junior',
        biography: `
            <p class="regular-text">Jackson Roy est analyste junior chez PineStone pour les petites capitalisations et occupe le poste de généraliste.<br><br>M. Roy est titulaire d’un baccalauréat en commerce, finance de l’Université Concordia.</p>
        `
      }
    ],

    Team3: [
      {
        img: '/img/team/nadim-rizk-2.jpg',
        imgsmall: '/img/team/nadim-rizk-small.png',
        split: '',
        name: 'Nadim Rizk, MBA, CFA<br>Fondateur',
        poste: 'Chef de la direction et chef des placements',
        biography: `
            <p class="regular-text">Nadim Rizk a fondé gestion d’actifs PineStone en 2021 et agit à titre de PDG et de chef des placements de la société. En tant qu’architecte de la philosophie et du processus de placement de la société, il est le gestionnaire de portefeuille principal des stratégies d’actions américaines, internationales et mondiales depuis leur création. Auparavant, M. Rizk était chef de l’équipe Actions mondiales chez Fiera Capital. Avant de se joindre à Fiera Capital, il était associé et gestionnaire de portefeuille principal des stratégies d’actions mondiales et américaines dans une société de placement privée à Montréal. M. Rizk a commencé sa carrière comme analyste de recherche sur les actions pour CN Investments. M. Rizk siège au conseil d’administration et préside le comité d’investissement de la Fondation du cancer des Cèdres. Il est également expert mondial Desautels pour le programme Honors in Investment Management de l’Université McGill.<br><br>M. Rizk est titulaire d’un baccalauréat en commerce de l’Université américaine de Beyrouth et d’une maîtrise en administration des affaires de l’Université McGill. Il est titulaire d’une charte CFA du CFA Institute.</p>
        `
      },
      {
        img: '/img/team/andrew-chan.jpg',
        imgsmall: '/img/team/andrew-chan-small.png',
        split: '',
        name: 'Andrew Chan, CIM, M.Sc.<br>Partenaire',
        poste: 'Directeur de recherche',
        biography: `
            <p class="regular-text">Andrew Chan est directeur de recherche chez PineStone. M. Chan est responsable de l’élaboration continue du processus de recherche fondamentale de la société et des outils exclusifs utilisés par l’équipe. M. Chan collabore avec le PDG et DPI dans la prise de décisions concernant l’élaboration du portefeuille, ainsi qu’avec les analystes de la société pour établir les priorités de recherche, créer et peaufiner les modèles financiers et assurer l’uniformité des documents de recherche définitifs. M. Chan est le gestionnaire de portefeuille suppléant des stratégies de la société et continue d’assumer la responsabilité de la recherche pour certains des postes de base du portefeuille. Avant de participer à la fondation de PineStone, M. Chan travaillait comme directeur de la recherche pour l’équipe Actions mondiales de Fiera Capital. Il a également occupé des postes de recherche sur les actions de deux sociétés de placement privées établies à Montréal. <br><br>M. Chan est titulaire d’un baccalauréat en commerce de l’Université McGill et d’une maîtrise en finance de HEC Montréal. Il détient le titre de gestionnaire de placements agréé de CSI.</p>
        `
      },
      {
        img: '/img/team/thomas-m-clancy.jpg',
        imgsmall: '/img/team/thomas-m-clancy-small.png',
        split: '',
        name: 'Thomas M. Clancy, MBA<br>Partenaire',
        poste: 'Chef des relations clients',
        biography: `
            <p class="regular-text">Thomas Clancy est chef des relations clients chez PineStone, où il dirige la gestion des relations, le développement des affaires, les relations avec les conseillers et les fonctions de marketing de la société. Avant de participer à la fondation de PineStone, M. Clancy était chef de la distribution américaine pour Fiera Capital. M. Clancy a passé toute sa carrière dans le domaine de la gestion des actifs institutionnels, occupant des postes liés au service à la clientèle, au développement des affaires et aux relations avec les conseillers. Il défend avec passion les intérêts de ses clients et veille à ce que ces intérêts demeurent au sommet des priorités de sa société.<br><br>M. Clancy est titulaire d’un baccalauréat ès sciences en administration des affaires de l’Université d’État de Framingham et d’une maîtrise en administration des affaires de l’Université Northeastern.</p>
        `
      },
      {
        img: '/img/team/david-doumani.jpg',
        imgsmall: '/img/team/david-doumani-small.png',
        split: '',
        name: 'David Doumani, CRMA, CAMS',
        poste: 'Chef de la conformité',
        biography: `
            <p class="regular-text">David Doumani est chef de la conformité chez PineStone. Il est responsable de la mise en œuvre et de la surveillance du cadre, des politiques et des procédures de conformité de la société qui garantissent que PineStone respecte les lois, les règlements et les pratiques exemplaires de l’industrie. M. Doumani supervise également l’adhésion de PineStone aux lignes directrices en matière d’investissement et dirige les efforts en matière d’ESG. <br><br>Avant de se joindre à PineStone, M. Doumani était chef de la conformité d’une société de gestion d’actifs établie à Montréal. Ses expériences antérieures comprennent des rôles semblables en matière de conformité, de gestion des risques et de vérification interne.<br><br>M. Doumani est titulaire d’un baccalauréat en sciences physiques et d’une maîtrise en gestion de l’Université de Melbourne. Il est titulaire d’un certificat en assurance de la gestion des risques (CRMA) et spécialiste certifié en lutte contre le blanchiment d’argent (CAMS).</p>
        `
      },
      {
        img: '/img/team/claudia-gourde.jpg',
        imgsmall: '/img/team/claudia-gourde-small.png',
        split: '',
        name: 'Claudia Gourde, LL.B.',
        poste: 'Chef des affaires juridiques et chef des opérations',
        biography: `
            <p class="regular-text">Claudia Gourde est la Chef des affaires juridiques et des opérations de PineStone. Elle supervise les affaires juridiques ainsi que les opérations de l'entreprise.<br><br>Avocate de formation (LL.B de l'Université de Montréal, membre du Barreau du Québec et Programme professionnel de conformité et de réglementation FINRA à la Wharton School of Business) possédant près de 25 ans d'expérience dans le secteur financier, elle a débuté sa carrière en travaillant dans la salle des marchés de la Bourse de Montréal alors qu’elle s'est rapidement initiée au monde de la finance. Avant de rejoindre PineStone, elle a travaillé pour diverses entreprises canadiennes ainsi que pour un groupe financier international suisse, occupant différents postes impliquant la conformité, le droit, les opérations et l'informatique, où elle a apporté leadership, dynamisme et efficacité. Elle a fait ses preuves en matière de gestion du changement, d'élaboration et d'exécution de stratégies, de plans et de projets commerciaux, tout en favorisant des relations de travail productives avec ses collègues et ses clients.<br><br>En tant que femme leader, elle s'engage également à faire progresser les femmes dans le leadership et à favoriser le progrès, une passion motivée par sa propre expérience.</p>
        `
      },
      {
        img: '/img/team/audrey-huot.jpg',
        imgsmall: '/img/team/audrey-huot-small.png',
        split: '',
        name: 'Audrey Huot, CPA, CA',
        poste: 'Chef des finances',
        biography: `
            <p class="regular-text">Mme Huot est chef des finances chez PineStone. Elle est responsable de la direction des fonctions de planification et d’analyse financières et assure la liaison avec les fournisseurs de services en ce qui concerne les questions organisationnelles et financières.<br><br>Avant de participer à la fondation de PineStone, Mme Huot a commencé sa carrière en 2006 chez PwC. Mme Huot était principalement responsable des services-conseils, se spécialisant dans les domaines de l’insolvabilité et de la restructuration, de la juricomptabilité et de la quantification des dommages. Elle a travaillé pour des entreprises privées œuvrant dans divers secteurs. Au cours de ses années chez PwC, elle a également travaillé sur les flux de trésorerie et les projections, sur les actifs non durables cédés ainsi que sur la restructuration de la dette, la juricomptabilité et la quantification des dommages.<br><br>Mme Huot a obtenu un baccalauréat en sciences, comptabilité et un diplôme d’études supérieures spécialisées de l’Université de Sherbrooke. Mme Huot est comptable professionnelle agréée (Canada).</p>
        `
      },
      {
        img: '/img/team/viken-paragamyan.jpg',
        imgsmall: '/img/team/viken-paragamyan-small.png',
        split: '',
        name: 'Viken Paragamyan',
        poste: 'Directeur des operations',
        biography: `
            <p class="regular-text">Viken Paragamyan est le chef des opérations de PineStone. Il supervise les fonctions de négociation, d’exploitation et de technologie de l’information de l’entreprise.  Auparavant, M. Paragamyan a occupé un poste semblable chez Presima inc. pendant 14 ans. M. Paragamyan a également géré le rééquilibrage des clients, les relations avec les courtiers et les tiers, l’administration du portefeuille et l’infrastructure de TI de la société.<br><br>M. Paragamyan s’est joint au secteur financier en 1998, après avoir travaillé comme négociateur international chez Jarislowsky Fraser, où il a participé à la négociation de portefeuilles et au processus d’exploitation interne, dont le maintien des relations avec les courtiers et la préparation de rapports d’attribution.<br><br>M. Paragamyan est titulaire d’un baccalauréat en commerce, finance de l’Université Concordia.</p>
        `
      }
    ]
  },
  en: {
    'menu-1': 'Home',
    'menu-2': 'About Us',
    'menu-3': 'Strategies',
    'menu-4': 'Contact',
    Team: 'Team',

    'footer-1': 'ESG Policy',
    'footer-2': 'Privacy Notice',
    'footer-3': 'Terms of use',

    'hero-title-1': 'Specialist Global Equity Manager',
    'hero-title-2': 'PineStone Asset Management Inc.',

    'privacy-title': 'Privacy Notice',

    more: 'Learn more',

    'about-title-1': 'About PineStone',
    'about-title-2': `Based in Montreal<br>Founded in 2021 by <a href="/nadim-rizk">Nadim Rizk</a>`,
    'about-desc':
      'PineStone is 100% employee-owned and founder-led. We serve our clients by investing in what we believe to be the highest quality companies worldwide and owning them for as long as possible, often decades. We are of the opinion these quality compounders can generate superior shareholder value for our clients over the long term.',

    'home-stat-1': `<p class="big">25+</p>
                    <p class="regular-text">Years of<br>Experience</p>`,
    'home-stat-2': `<p class="big">Singular</p>
                    <p class="regular-text">focus on Quality Compounders</p>`,
    'home-stat-3': `<p class="big">Alignment</p>
                    <p class="regular-text">Through ownership, compensation, and investments</p>`,

    'about-hero-1': 'About Us',
    'about-hero-2': 'PineStone Asset Management Inc.',

    'about-content-title-1': 'Investment Team<br>Formed in 2009',
    'about-content-desc-1': `<h4 class="title small">PineStone Asset Management Inc.,</h4>
                             <p class="regular-text">was established in 2021, as a result of our investment team's spin out of a large publicly traded asset manager. Our team has collaborated closely since 2009 and has been recognized with numerous industry awards. PineStone considers it an honor and privilege to manage assets on behalf of many of the world's largest and most sophisticated investors.</p>`,

    'about-content-title-2': 'Actively Patient',
    'about-content-desc-2': `<h4 class="title small">We are actively patient with our investments,</h4>
                             <p class="regular-text">looking through short-term challenges while doing the work to confirm our investment thesis remains intact. We believe a significant edge is derived from the depth of knowledge we accumulate by adopting an owner's mindset and holding our companies over many market cycles.</p>`,

    'about-content-title-3': 'Why the name?',
    'about-content-desc-3': `<p class="regular-text">
    <strong style="font-size: 150%;">PineStone’s logo features an umbrella-shaped Pine Tree native to the Mediterranean, including Lebanon.</strong> <br/><br/> We envision the tree’s umbrella shape representing the downside protection our Investment Team strives for, shielding our client’s assets over many market cycles and the pine nuts; those are the dividends our companies pay to our clients over the many years we own them.</p>`,

    'about-content-title-4': 'The Stone Pine Tree (Pinus Pinea),',

    'about-stat-1': `<p class="big mh"><span class="top">Our</span><br>Mission</p>
                     <p class="regular-text small">To create extraordinary value for our clients and their beneficiaries over many years </p>`,
    'about-stat-2': `<p class="big mh"><span class="top">Our</span><br>Vision</p>
                     <p class="regular-text small">To be the gold standard for fundamental research, portfolio construction, and long-term quality growth investing </p>`,
    'about-stat-3': `<p class="big mh"><span class="top">Our</span><br>Values</p>
                     <p class="regular-text small">We foster a culture of commitment, integrity, drive, diligence, and patience</p>`,

    'strat-hero-1': 'Our',
    'strat-hero-2': 'Strategies',

    'strat-content-title-1': 'High Conviction',
    'strat-content-desc-1': `<h4 class="title small">PineStone manages high conviction,</h4>
                             <p class="regular-text">long-only equity strategies for a primarily institutional client base. We invest in fewer holdings but maintain appropriate diversification as we seek to generate meaningful excess returns for our clients. Our goal is to outperform their benchmarks over the long-term, with a lower likelihood of permanent capital loss, and we invest in our strategies alongside our clients.</p>`,

    'strat-content-title-2': 'Research Driven',
    'strat-content-desc-2': `<h4 class="title small">To help us achieve our performance goals,</h4>
                             <p class="regular-text">we rely on bottom-up stock selection, driven by our intensive fundamental research process. Our investment process is designed to help us assess whether potential investments have the high-quality attributes we seek and whether those attributes are likely to be sustained long into the future. Companies that meet our standards are difficult to find, leading to concentrated portfolios and material deviations from the benchmark.</p>`,

    'strat-stat-1': `<p class="big mh">Global<br><span class="bottom">equity</span></p>
                    <div class="table">
                      <div class="row">
                        <div class="cell color"><p>Inception Date</p></div>
                        <div class="cell"><p>October 1st, 2009</p></div>
                      </div>
                      <div class="row">
                        <div class="cell color"><p>Target Number of Holdings</p></div>
                        <div class="cell"><p>25-35</p></div>
                      </div>
                      <div class="row">
                        <div class="cell color"><p>Expected Annual Turnover</p></div>
                        <div class="cell"><p>5-10%</p></div>
                      </div>
                    </div>
    `,
    'strat-stat-2': `<p class="big mh smaller">International<br><span class="bottom">equity</span></p>
                    <div class="table">
                      <div class="row">
                        <div class="cell color"><p>Inception Date</p></div>
                        <div class="cell"><p>January 1st, 2010</p></div>
                      </div>
                      <div class="row">
                        <div class="cell color"><p>Target Number of Holdings</p></div>
                        <div class="cell"><p>25-35</p></div>
                      </div>
                      <div class="row">
                        <div class="cell color"><p>Expected Annual Turnover</p></div>
                        <div class="cell"><p>5-10%</p></div>
                      </div>
                    </div>                 
    `,
    'strat-stat-3': `<p class="big mh">US<br><span class="bottom">equity</span></p>
                    <div class="table">
                      <div class="row">
                        <div class="cell color"><p>Inception Date</p></div>
                        <div class="cell"><p>April 1st, 2009</p></div>
                      </div>
                      <div class="row">
                        <div class="cell color"><p>Target Number of Holdings</p></div>
                        <div class="cell"><p>20-30</p></div>
                      </div>
                      <div class="row">
                        <div class="cell color"><p>Expected Annual Turnover</p></div>
                        <div class="cell"><p>5-10%</p></div>
                      </div>
                    </div>
    `,

    'nadim-hero-1': 'About our founder',
    'nadim-hero-2': 'Nadim Rizk, Mba, CFA',
    'nadim-title': 'Chief Executive Officer & Chief Investment Officer',
    'nadim-desc': `Nadim Rizk founded PineStone Asset Management in 2021 to realize his career-long dream of having an employee-owned, laser-focused, and client-centric investment boutique.<br><br>Nadim was born and raised in Beirut, Lebanon. As an adolescent, he was introduced to investing by his father, a dedicated family physician. In addition to igniting Nadim’s passion for investing, his parents instilled a deep sense of commitment, integrity, work ethic, and family orientation. These values are engrained in PineStone’s culture and help fortify the investment team’s discipline in applying our investment approach and our firm’s relentless focus on clients.<br><br>Nadim earned his Bachelor of Commerce in Finance from the American University of Beirut. He moved to Canada to pursue his Master of Business Administration at McGill University and has earned the right to use the Chartered Financial Analyst designation. Nadim serves on the board of the Cedars Cancer Foundation, The McGill University Hospital Center Investment Committee, and is an active participant as a global expert for the Honors in Investment Management program at McGill University. He lives in Montreal with his wife and their three children.`,

    'contact-hero-1': 'Contact Us',

    'meta-title-home': 'Specialist Global Equity Manager',
    'meta-title-about': 'PineStone Asset Management Inc.',
    'meta-title-nadim': 'Nadim Rizk',
    'meta-title-strategies': 'PineStone Strategies',
    'meta-title-contact': 'Contact PineStone',
    'meta-desc':
      'PineStone Asset Management Inc. was formed following our Investment Team’s separation from a large publicly traded asset manager. Our Team has worked together for more than a decade and has been recognized with numerous industry awards. PineStone is honored to manage assets on behalf of some of the world’s most sophisticated institutional investors.',

    'contact-address': '1981 McGill College Avenue, Suite 1600<br />Montreal, QC, H3A 2Y1',
    'contact-phone': 'P. 438-793-0444',
    'contact-email': 'E. info@pinestoneam.com',
    'contact-cta-email': 'Email Us',
    'form-title': 'WRITE TO US',
    'form-name': 'First and last name',
    'form-mail': 'E-mail',
    'form-phone': 'Phone number',
    'form-business': 'Organization name',
    'form-investisseur': 'Which describes you best?',
    'form-investisseur-option-1': 'Institutional Investor',
    'form-investisseur-option-2': 'Investment Consultant',
    'form-investisseur-option-3': 'Other',
    'form-interet': 'Strategy interest?',
    'form-interet-option-1': 'Global Equity',
    'form-interet-option-2': 'International Equity',
    'form-interet-option-3': 'US Equity',
    'form-send': 'Send',

    'team-hero-1': 'Our',
    'team-hero-2': 'Team',
    'team-title-1': 'PineStone Investment Team',
    'team-title-2': 'Small Cap Investment Team',
    'team-title-3': 'PineStone Management Committee',
    bio: 'Biography',

    'chief-compliance': 'Chief Compliance Officer',
    'chief-compliance-text':
      'Pinestone Asset Management Inc. has appointed David Doumani, Chief Compliance Officer, as its Chief Privacy Officer. If you have any questions about this Privacy Notice, about your Chief Privacy Officer or, more generally, about the processing of your Personal Data, you can contact the Chief Privacy Officer below.',
    'chief-compliance-sub-title': 'Chief Privacy Officer  ',
    'chief-compliance-sub-text':
      'David Doumani<br>Chief Privacy Officer<br><br>PineStone Asset Management Inc.<br>1600 McGill College Avenue, Suite 1600<br>Montreal (Quebec) H3A 2Y1',

    'policies-title': 'Policies',

    Team1: [
      {
        img: '/img/team/nadim-rizk-2.jpg',
        imgsmall: '/img/team/nadim-rizk-small.png',
        split: 'w50',
        name: 'Nadim Rizk, MBA, CFA<br>Founder',
        poste: 'Chief Executive Officer & Chief Investment Officer',
        biography: `
            <p class="regular-text">Nadim Rizk founded PineStone Asset Management in 2021 and serves as the firm’s CEO & CIO. As the architect of the firm’s investment philosophy and process, he has served as the Lead Portfolio Manager of the U.S., International, and Global equity strategies since their inception.  Previously, Mr. Rizk was the head of the Global Equity team at Fiera Capital.  Prior to joining Fiera Capital, he was a partner and lead portfolio manager for Global and US Equity Strategies at a privately held investment firm in Montreal. Mr. Rizk began his career as an equity research analyst for CN Investments. Mr. Rizk serves on the Board of Directors and Chairs the Investment Committee for the Cedars Cancer Foundation. He also serves as a Desautels Global Expert for the Honors in Investment Management Program at McGill University. <br><br>Mr. Rizk earned a Bachelor of Commerce degree from the American University of Beirut and his Master of Business Administration from McGill University. He is a CFA charterholder, CFA Institute.</p>
        `
      },
      {
        img: '/img/team/andrew-chan.jpg',
        imgsmall: '/img/team/andrew-chan-small.png',
        split: 'w50',
        name: 'Andrew Chan, CIM, M.Sc.<br>Partner',
        poste: 'Head of Research',
        biography: `
            <p class="regular-text">Andrew Chan serves as PineStone’s Head of Research. Mr. Chan is responsible for the ongoing development of the firm’s fundamental research process and the proprietary tools the team utilizes.  Mr. Chan works with the firm’s CEO & CIO on portfolio construction decisions and with the firm’s analysts to set research priorities, develop and fine-tune the financial models, and ensure consistency of the final research documents. Mr. Chan is the backup portfolio manager on the firm’s strategies and continues to hold research responsibility for some of the portfolio's core positions. Before assisting in founding PineStone, Mr. Chan worked as the Director of Research for the Global Equity Team at Fiera Capital, where he was one of the original five team members. He has also held roles in equity research for two private, Montreal-based investment firms. <br><br>Mr. Chan earned a Bachelor of Commerce from McGill University and a Master’s in Finance from HEC Montreal. He holds the Chartered Investment Manager designation from the Canadian Securities Institute.</p>
        `
      },
      {
        img: '/img/team/nick-cileli.jpg',
        imgsmall: '/img/team/nick-cileli-small.png',
        split: '',
        name: 'Nick Cileli, CFA<br>Partner',
        poste: 'Senior Analyst',
        biography: `
            <p class="regular-text">Nick Cileli serves as a Senior Analyst at PineStone and holds research coverage for the Global Financials sector. Prior to PineStone, Mr. Cileli was a Senior Analyst with the team at Fiera Capital, joining as one of the original five team members. For more than 25 years, Mr. Cileli has held positions in equity research, treasury management, and financial auditing. <br><br>Mr. Cileli earned a Bachelor of Commerce, Finance, from Concordia University and is a CFA charterholder, CFA Institute.</p>
        `
      },
      {
        img: '/img/team/dominic-tremblay.jpg',
        imgsmall: '/img/team/dominic-tremblay-small.png',
        split: '',
        name: 'Dominic Tremblay, M.Sc, CFA<br>Partner',
        poste: 'Senior Analyst',
        biography: `
            <p class="regular-text">Dominic Tremblay serves as a Senior Analyst at PineStone and holds research coverage for the Global Health Care sector and secondary coverage for the Global Financials sector. Prior to PineStone, Mr. Tremblay was a Senior Analyst with the team at Fiera Capital, joining as one of the original five team members. For more than 20 years, Mr. Tremblay has held positions in equity research and financial analysis.<br><br>Mr. Tremblay earned a Bachelor of Business Administration, Finance, and a Master of Science in Finance from the Université de Sherbrooke and is a CFA Charterholder, CFA Institute.</p>
        `
      },
      {
        img: '/img/team/juhua-shi.jpg',
        imgsmall: '/img/team/juhua-shi-small.png',
        split: '',
        name: 'Juhua Shi, MBA, CFA<br>Partner',
        poste: 'Senior Analyst',
        biography: `
            <p class="regular-text">Juhua Shi serves as a Senior Analyst at PineStone and holds research coverage for the Technology and Communication Services sectors. Prior to PineStone, Mr. Shi was a Senior Analyst with the team at Fiera Capital for nearly a decade. Beginning his career in China as a computer engineer, Mr. Shi has more than thirty years of experience in equity research, portfolio management, and engineering. <br><br>Mr. Shi earned a Bachelor of Engineering, Computer Engineering from Shanghai Jiao Tong University, a Master of Business Administration from McGill University, and is a CFA Charterholder, CFA Institute.</p>
        `
      },
      {
        img: '/img/team/wen-xu.jpg',
        imgsmall: '/img/team/wen-xu-small.png',
        split: '',
        name: 'Wen Qing Xu, CFA<br>Partner',
        poste: 'Senior Analyst',
        biography: `
            <p class="regular-text">Wen Qing Xu serves as a Senior Analyst at PineStone and holds research coverage for the Global Consumers Sectors. Prior to PineStone, Ms. Xu was an analyst with the team at Fiera Capital for more than five years. <br><br>Ms. Xu earned a Bachelor of Commerce, Finance from McGill University, and is a CFA Charterholder, CFA Institute.</p>
        `
      },
      {
        img: '/img/team/ayssar-fernandez.jpg',
        imgsmall: '/img/team/ayssar-fernandez-small.png',
        split: '',
        name: 'Ayssar Fernandez, CFA<br>Partner',
        poste: 'Senior Analyst ',
        biography: `
            <p class="regular-text">Ayssar Fernandez serves as a Senior Analyst at PineStone and has research coverage for the Information Technology and Consumer sectors. Prior to PineStone, Mr. Fernandez was an analyst with the team at Fiera Capital for more than four years. <br><br>Mr. Fernandez earned a Bachelor of Commerce, Finance from Concordia University, and is a CFA Charterholder, CFA Institute.</p>
        `
      },
      {
        img: '/img/team/stephano-pascali.jpg',
        imgsmall: '/img/team/stephano-pascali-small.png',
        split: '',
        name: 'Stephano Pascali',
        poste: 'Junior Analyst',
        biography: `
            <p class="regular-text">Stephano Pascali serves as a Junior Analyst at PineStone and occupies the position as a Generalist.<br><br>Mr. Pascali earned a Bachelor of Commerce, Finance from Concordia University.</p>
        `
      }
    ],

    Team2: [
      {
        img: '/img/team/thomas-horvath.jpg',
        imgsmall: '/img/team/thomas-horvath-small.png',
        split: '',
        name: 'Thomas Horvath, MBA, CFA, CAIA<br>Partner',
        poste: 'Lead Portfolio Manager',
        biography: `
            <p class="regular-text">Thomas Horvath serves as the Lead Portfolio Manager for the Global Small Cap Strategy. Prior to PineStone, Mr. Horvath was the Lead Analyst for the Global Equity Team at Fiera Capital, where he joined in 2009 as one of the original five team members.   <br><br>Mr. Horvath earned a Bachelor of Commerce, Finance from Concordia University, a Master of Business Administration from Queens University, and is a CFA Charterholder, CFA Institute. Mr. Horvath also holds the CAIA designation.</p>
        `
      },
      {
        img: '/img/team/ivana-miladinovic.jpg',
        imgsmall: '/img/team/ivana-miladinovic-small.png',
        split: '',
        name: 'Ivana Miladinovic, CFA',
        poste: 'Lead Analyst',
        biography: `
            <p class="regular-text">Ivana Miladinovic serves as the Lead Analyst for the PineStone Global Small Cap Strategy and holds primary research coverage for the Global Industrials and Financials Sectors. In addition to her work on the Global Small Cap Strategy, Ms. Miladinovic continues to offer support to the large-cap strategies on an interim basis. Prior to PineStone, Ms. Miladinovic worked for more than six years with the team at Fiera Capital. She has more than thirteen years of experience in equity research and investment banking.<br><br>Ms. Miladinovic earned a Bachelor of Commerce, Finance from Concordia University, and is a CFA Charterholder, CFA Institute. Ms. Miladinovic has also earned a CFA UK Level 4 Certificate in ESG Investing.</p>
        `
      },
      {
        img: '/img/team/jackson-roy.jpg',
        imgsmall: '/img/team/jackson-roy-small.png',
        split: '',
        name: 'Jackson Roy',
        poste: 'Junior Analyst',
        biography: `
            <p class="regular-text">Jackson Roy serves as a Junior Analyst at PineStone and occupies the position as a Generalist on the Small Cap Investment Team.<br><br>Mr. Roy will complete his Bachelor of Commerce, Finance from Concordia University in 2024.</p>
        `
      }
    ],

    Team3: [
      {
        img: '/img/team/nadim-rizk-2.jpg',
        imgsmall: '/img/team/nadim-rizk-small.png',
        split: '',
        name: 'Nadim Rizk, MBA, CFA<br>Founder',
        poste: 'Chief Executive Officer & Chief Investment Officer',
        biography: `
            <p class="regular-text">Nadim Rizk founded PineStone Asset Management in 2021 and serves as the firm’s CEO & CIO. As the architect of the firm’s investment philosophy and process, Mr. Rizk has served as the Lead Portfolio Manager of the U.S., International, and Global equity strategies since their inception.  Previously, Mr. Rizk was the head of the Global Equity team at Fiera Capital.  Prior to joining Fiera Capital, Mr. Rizk was a partner and lead portfolio manager for Global and US Equity Strategies at a privately held investment firm in Montreal. Mr. Rizk began his career as an equity research analyst for CN Investments. Mr. Rizk serves on the Board of Directors and Chairs the Investment Committee for the Cedars Cancer Foundation. Mr. Rizk also serves as a Desautels Global Expert for the Honors in Investment Management Program at McGill University.<br><br>Mr. Rizk earned a Bachelor of Commerce degree from the American University of Beirut and his Master of Business Administration from McGill University. Mr. Rizk is a CFA charterholder, CFA Institute.</p>
        `
      },
      {
        img: '/img/team/andrew-chan.jpg',
        imgsmall: '/img/team/andrew-chan-small.png',
        split: '',
        name: 'Andrew Chan, CIM, M.Sc.<br>Partner',
        poste: 'Head of Research',
        biography: `
            <p class="regular-text">Andrew Chan serves as PineStone’s Head of Research. Mr. Chan is responsible for the ongoing development of the firm’s fundamental research process and the proprietary tools the team utilizes.  Mr. Chan works with the firm’s CEO & CIO on portfolio construction decisions and with the firm’s analysts to set research priorities, develop and fine-tune the financial models, and ensure consistency of the final research documents. Mr. Chan is the backup portfolio manager on the firm’s strategies and continues to hold research responsibility for some of the portfolio's core positions. Before assisting in founding PineStone, Mr. Chan worked as the Director of Research for the Global Equity Team at Fiera Capital. He has also held roles in equity research for two private, Montreal-based investment firms.<br><br>Mr. Chan earned a Bachelor of Commerce from McGill University and a Master’s in Finance from HEC Montreal. He holds the Chartered Investment Manager designation from the Canadian Securities Institute.</p>
        `
      },
      {
        img: '/img/team/thomas-m-clancy.jpg',
        imgsmall: '/img/team/thomas-m-clancy-small.png',
        split: '',
        name: 'Thomas M. Clancy, MBA<br>Partner',
        poste: 'Head of Client Relations',
        biography: `
            <p class="regular-text">Thomas Clancy serves as PineStone’s Head of Client Relations, leading the firm’s relationship management, business development, consultant relations, and marketing functions. Prior to participating in the founding of PineStone, Mr. Clancy was the Head of US Distribution for Fiera Capital. Mr. Clancy has spent his entire career in the institutional asset management business, working in client servicing, business development, and consultant relations roles. He is a passionate advocate for his clients and works to ensure their interests are kept at the top of his firm’s priorities.<br><br>Mr. Clancy earned a Bachelor of Science in Business Administration from Framingham State University and his Master of Business Administration from Northeastern University.</p>
        `
      },
      {
        img: '/img/team/david-doumani.jpg',
        imgsmall: '/img/team/david-doumani-small.png',
        split: '',
        name: 'David Doumani, CRMA, CAMS',
        poste: 'Chief Compliance Officer',
        biography: `
            <p class="regular-text">David Doumani serves as PineStone’s Chief Compliance Officer. He is responsible for implementing and monitoring the firm’s compliance framework, policies, and procedures that ensure PineStone complies with the relevant laws, regulations, and industry best practices. Mr. Doumani also oversees PineStone’s adherence to investment guidelines and spearheads PineStone’s firm-level ESG efforts.<br><br>Prior to joining PineStone, Mr. Doumani served as the Chief Compliance Officer of a Montreal-based Asset Management Firm. His prior experiences involve similar roles in compliance, risk management, and internal audit.<br><br>Mr. Doumani earned a bachelor's degree, Physical Sciences, and a Master’s Degree, Management from the University of Melbourne. He holds a Certification in Risk Management Assurance (CRMA), and is a Certified Anti-Money Laundering Specialist (CAMS).</p>
        `
      },
      {
        img: '/img/team/claudia-gourde.jpg',
        imgsmall: '/img/team/claudia-gourde-small.png',
        split: '',
        name: 'Claudia Gourde, LL.B.',
        poste: 'General Counsel and Chief Operating Officer',
        biography: `
            <p class="regular-text">Claudia Gourde is PineStone's Chief Legal and Operations Officer. She oversees the legal affairs as well as the operations of the company.<br><br>A lawyer by training (LL.B from the Université de Montréal, member of the Quebec Bar and FINRA Professional Compliance and Regulation Program at the Wharton School of Business) with nearly 25 years of experience in the financial sector, she began her career working on the trading floor of the Montreal Exchange while quickly introducing herself to the world of finance.<br><br>Prior to joining PineStone, she worked for various Canadian companies as well as for a Swiss international financial group, holding various positions involving compliance, legal, operations and IT, where she brought leadership, dynamism and efficiency. She has a proven track record of managing change, developing and executing business strategies, plans and projects, while fostering productive working relationships with colleagues and clients.<br><br>As a female leader, she is also committed to advancing women in leadership and driving progress, a passion driven by her own experience.</p>
        `
      },
      {
        img: '/img/team/audrey-huot.jpg',
        imgsmall: '/img/team/audrey-huot-small.png',
        split: '',
        name: 'Audrey Huot, CPA, CA',
        poste: 'Head of Finance',
        biography: `
            <p class="regular-text">Ms. Huot serves as PineStone’s Head of Finance. She is responsible for leading the financial planning and analysis functions and acts as a liaison with service providers regarding corporate and financial matters. Prior to assisting in the founding of PineStone, Ms. Huot started her career in 2006 at PwC. Ms. Huot was primarily responsible for Corporate Advisory, specializing in Insolvency and Restructuring, Forensic accounting, and Damage quantifications. She worked with private companies operating in a wide variety of sectors. During her years at PwC, she also worked on cash flows and projections, divested non-viable assets, and worked on debt restructuring, forensic accounting, and damage quantification.<br><br>Ms. Huot earned a Bachelor of Science, Accounting and a Specialized Graduate Diploma from the Université de Sherbrooke. Ms. Huot is a Chartered Professional Accountant (Canada).</p>
        `
      },
      {
        img: '/img/team/viken-paragamyan.jpg',
        imgsmall: '/img/team/viken-paragamyan-small.png',
        split: '',
        name: 'Viken Paragamyan',
        poste: 'Head of Operations',
        biography: `
            <p class="regular-text">Viken Paragamyan serves as PineStone’s Head of Operations. He oversees the firm’s trading, operations, and information technology functions. Previously, Mr. Paragamyan held a similar role at Presima Inc., for 14 years.<br><br>Mr. Paragamyan joined the financial industry in 1998, having worked as an International Trader at Jarislowsky Fraser. There, he traded portfolios and was active in the internal operations process, including maintaining broker relationships and preparing attribution reports.<br><br>Mr. Paragamyan earned a Bachelor of Commerce, Finance from Concordia University.</p>
        `
      }
    ]
  }
}

export default trans
